@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    //background: #fff1f1;
    font-family: "Nunito", sans-serif;
}

.container {
    width: 75%;
    max-width: 700px;
    margin: 1.5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 650px) {
    .container {
        width: 85%;
    }
}
.container .header {
    color: #fb3958;
    font-size: 5em;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px 5px #b1041f;
}
@media (max-width: 650px) {
    .container .header {
        font-size: 3em;
    }
}

.compcontainer {
    width: 75%;
    height: 13rem;
    padding: 1rem 0;
}
@media (max-width: 650px) {
    .compcontainer {
        height: 10rem;
    }
}
.compcontainer svg {
    max-width: 100%;
    max-height: 100%;
    animation: bouncy 1300ms linear infinite;
}

.instructions {
    background: #fefefe;
    width: 80%;
    height: auto;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 0.25rem;
}
@media (max-width: 650px) {
    .instructions {
        width: 100%;
    }
}
.instructions h2 {
    font-size: 1.25em;
    line-height: 1.3;
    color: #e30528;
}
@media (max-width: 650px) {
    .instructions h2 {
        font-size: 1.05em;
    }
}
.instructions p {
    font-size: 1.15em;
    line-height: 1.5;
    color: #122125;
}
@media (max-width: 650px) {
    .instructions p {
        font-size: 1em;
    }
}
.instructions .step {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 1.5rem;
    margin: 0.5rem 0 1rem 0;
}
.instructions .step .icon {
    width: 1.25rem;
    height: 1.25rem;
    align-self: center;
}
@media (max-width: 650px) {
    .instructions .step .icon {
        width: 1rem;
        height: 1rem;
    }
}
.instructions .step p {
    display: inline-block;
    width: 80%;
    line-height: 1.5;
    padding-left: 0.5rem;
}

@keyframes bouncy {
    0% {
        transform: translateY(10px) translateX(0) rotate(0);
    }
    25% {
        transform: translateX(-10px) rotate(-10deg);
    }
    50% {
        transform: translateX(0) rotate(0deg);
    }
    75% {
        transform: translateX(10px) rotate(10deg);
    }
    100% {
        transform: translateY(10px) translateX(0) rotate(0);
    }
}
